import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Marketplaces = () => (
  <Layout>
    <Seo title="Marketplaces" />
      <section className='section-title white'>
          <div className='container clearfix'>
              <h1>Marketplaces</h1>
          </div>
      </section>

      <div className='container clearfix marketplaces'>
          <h4>Epochs</h4>
          <p className='big'>1. <a href="https://www.tensor.trade/trade/playground_epoch" target="_blank">Tensor</a></p>
          <p className='big'>2. <a href="https://hyperspace.xyz/collection/playgroundepoch" target="_blank">Hyperspace</a></p>
          <p className='big'>3. <a href="https://opensea.io/collection/playground-epochs" target="_blank">OpenSea</a></p>

          <h4>Waves</h4>
          <p className='big'>1. <a href="https://www.tensor.trade/trade/playground_waves" target="_blank">Tensor</a></p>
          <p className='big'>2. <a href="https://hyperspace.xyz/collection/playground" target="_blank">Hyperspace</a></p>
      </div>
  </Layout>
)

export default Marketplaces
